import { combineReducers } from 'redux'
import LoginReducer from './login'
import ModalReducer from './modal'
import MilestoneReducer from './milestone'

const RootReducer = combineReducers({
  LoginReducer,
  ModalReducer,
  MilestoneReducer,
})

export default RootReducer
