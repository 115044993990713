// 로컬스토리지 데이터 추가 or 수정
// 추가하거나 수정하고 싶은 데이터의 key 값, value 값을 파라미터(obj)에 객체로 넣으면 됩니다.
// 주의사항: 파라미터로 오직 1개의 객체 안에 1개 이상의 key:value 들을 넣어주세요
// 예: modifyLocalStroageItems({email: 'example@email.com', jwt:'exampleJSONWebToken'})
// 결과:
// 로컬스토리지에 파라미터로 들어온 key 값이 없을 경우: 파라미터(obj)의 { key : value } 값들 추가
// 로컬스토리지에 파라미터로 들어온 key 값이 있을 경우: 해당 key의 value를 파라미터(obj)의 key에 대응하는 value로 수정
export function modifyLocalStorageItems(obj) {
  if (window.localStorage.hasOwnProperty(process.env.REACT_APP_GRIDGE_WEB_INFO || '')) {
    const keys = Object.keys(obj).map((val) => {
      return val
    })
    const values = Object.values(obj).map((val) => {
      return val
    })
    let data = JSON.parse(window.localStorage.getItem(process.env.REACT_APP_GRIDGE_WEB_INFO || '') || '{}')

    for (let i = 0; i < keys.length; i++) {
      data = { ...data, [keys[i]]: values[i] }
    }

    window.localStorage.setItem(process.env.REACT_APP_GRIDGE_WEB_INFO || '', JSON.stringify(data))
  } else {
    window.localStorage.setItem(process.env.REACT_APP_GRIDGE_WEB_INFO || '', JSON.stringify(obj))
  }
}

// 로컬스토리지 데이터 삭제
// 삭제하고 싶은 데이터들의 키 값을 파라미터(...keys)에 String 타입으로 넣으면 됩니다.
// 주의사항: 파라미터로 1개 이상의 String 들만 넣어주세요.
// 예: removeLocalStorageItems('email', 'jwt')
// 결과: 로컬스토리지에 key 값이 email, jwt에 해당하는 key,value들 삭제
export function removeLocalStorageItems(...keys) {
  const data = JSON.parse(window.localStorage.getItem(process.env.REACT_APP_GRIDGE_WEB_INFO || '') || '{}')

  for (const el of keys) {
    delete data[el]
  }

  window.localStorage.setItem(process.env.REACT_APP_GRIDGE_WEB_INFO || '', JSON.stringify(data))
}

// 로컬스토리지 데이터 읽기
// 읽고 싶은 데이터의 키 값을 파라미터(key)에 String 타입으로 넣으면 됩니다.
// 주의사항: 파라미터로 오직 1개의 String 만 넣어주세요.
// 예: getLocalStorageItem('email')
// 결과: 로컬스토리지에 key 값이 email인 value 리턴 (모든 타입)
export function getLocalStorageItem(key) {
  const gridgeWebInfo = JSON.parse(window.localStorage.getItem(process.env.REACT_APP_GRIDGE_WEB_INFO || '') || '{}')
  return gridgeWebInfo?.[key]
}

// 로그인 시 로컬스토리지 데이터 저장
export function saveLoginData(data) {
  modifyLocalStorageItems({
    jwtNerd: data.jwt,
    idNerd: data.id,
    emailNerd: data.email,
    realNameNerd: data.realName,
    profileImageUrlNerd: data.profileImageUrl,
    phoneNumberNerd: data.phoneNumber,
    nickName: data.nickName,
    testStatus: data.testStatus,
    mainPlatform: data.mainPlatform,
    projectCount: data.projectCount,
    programCount: data.programCount,
  })
}

// 로그아웃 시 로컬스토리지 데이터 삭제 (autoLogin값에 따라 email 삭제여부 결정)
export function clearLoginData() {
  removeLocalStorageItems(
    'jwtNerd',
    'idNerd',
    'realNameNerd',
    'profileImageUrlNerd',
    'phoneNumberNerd',
    'nickName',
    'testStatus',
    'mainPlatform',
    'projectCount',
    'programCount',
  )

  if (!JSON.parse(window.localStorage.getItem(process.env.REACT_APP_GRIDGE_WEB_INFO || '') || '{}').autoLoginNerd) {
    removeLocalStorageItems('emailNerd')
  }
}
