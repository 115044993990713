export const TYPOGRAPHY = {
  H1_BOLD: {
    fontSize: '4.8rem',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '-2.4px',
  },
  H2_BOLD: {
    fontSize: '4rem',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '-2px',
  },
  H3_BOLD: {
    fontSize: '3.6rem',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '-1.8px',
  },
  T1_BOLD: {
    fontSize: '3.2rem',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '-1.6px',
  },
  T2_BOLD: {
    fontSize: '2.8rem',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '-1.4px',
  },
  SUB1_REGULAR: {
    fontSize: '2.4rem',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-1.2px',
  },
  SUB1_BOLD: {
    fontSize: '2.4rem',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '-1.2px',
  },
  SUB2_REGULAR: {
    fontSize: '2rem',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-1px',
  },
  SUB2_MEDIUM: {
    fontSize: '2rem',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '-1px',
  },
  SUB2_BOLD: {
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '-1px',
  },
  BODY1_REGULAR: {
    fontSize: '2rem',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-1px',
  },
  BODY1_MEDIUM: {
    fontSize: '2rem',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '-1px',
  },
  BODY1_BOLD: {
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '-1px',
  },
  BODY2_REGULAR: {
    fontSize: '1.6rem',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-0.8px',
  },
  BODY2_MEDIUM: {
    fontSize: '1.6rem',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '-0.8px',
  },
  BODY2_BOLD: {
    fontSize: '1.6rem',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '-0.8px',
  },
  BODY3_MEDIUM: {
    fontSize: '1.4rem',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '-0.7px',
  },
  BODY3_REGULAR: {
    fontSize: '1.4rem',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-0.7px',
  },
  CAPTION_REGULAR: {
    fontSize: '1.4rem',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-0.7px',
  },
  CAPTION_MEDIUM: {
    fontSize: '1.4rem',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '-0.7px',
  },
}
