import { createAction, createReducer } from '@reduxjs/toolkit'
import { clearLoginData, getLocalStorageItem, saveLoginData } from '../../dataManager/localStorageManager'

export interface User {
  nerd: Nerd | Record<string, never>
}

export interface Nerd {
  jwt: string
  id: number
  email: string
  realName: string
  profileImageUrl: string
  phoneNumber: string
  nickName: string
  testStatus: string | null
  mainPlatform: string | null
  projectCount: number | null
  programCount: number | null
}

const initialState: User = {
  nerd: {
    jwt: getLocalStorageItem('jwtNerd'), // jwt
    id: getLocalStorageItem('idNerd'), // id
    email: getLocalStorageItem('emailNerd'), // 이메일
    realName: getLocalStorageItem('realNameNerd'), // 이름
    profileImageUrl: getLocalStorageItem('profileImageUrlNerd'), // 프로필 이미지
    phoneNumber: getLocalStorageItem('phoneNumberNerd'), // 휴대폰번호
    nickName: getLocalStorageItem('nickName'), // 닉네임
    testStatus: getLocalStorageItem('testStatus'), // 그릿지테스트 상태 (null, "합격", "불합격")
    mainPlatform: getLocalStorageItem('mainPlatform'), // 직무 (null, "Server", "iOS", ...)
    projectCount: getLocalStorageItem('projectCount'), // 프로젝트 디데이 카운트 (null, Number)
    programCount: getLocalStorageItem('programCount'), // 프로그램 디데이 카운트 (null, Number)
  },
}

export const loginAction = createAction<Nerd>('LOGIN')
export const logoutAction = createAction<void>('LOGOUT')
export const modifyProfileImageUrl = createAction<string>('MODIFY_NERD_PROFILE_IMAGE')
export const modifyPhoneNumber = createAction<string>('MODIFY_NERD_PHONE')

const LoginReducer = createReducer(initialState, (builder) => {
  builder.addCase(loginAction, (state, action) => {
    saveLoginData(action.payload)
    state.nerd = action.payload
  })
  builder.addCase(logoutAction, (state) => {
    clearLoginData()
    state.nerd = {}
  })
  builder.addCase(modifyProfileImageUrl, (state, action) => {
    state.nerd.profileImageUrl = action.payload
  })
  builder.addCase(modifyPhoneNumber, (state, action) => {
    state.nerd.phoneNumber = action.payload
  })
})

export default LoginReducer
