import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import RootReducer from './store/reducers'
import { composeWithDevTools } from 'redux-devtools-extension' // 리덕스 개발자 도구
import 'react-app-polyfill/stable'
import { GlobalStyle } from './components/default/styled'
import * as ChannelService from '@channel.io/channel-web-sdk-loader'

const store = createStore(RootReducer, composeWithDevTools())

if (process.env.REACT_APP_ENV === 'prod') {
  // 채널톡 연동
  ChannelService.loadScript()
  ChannelService.boot({ pluginKey: 'a5cf1c2e-944a-431f-8e2c-3660ab35f61d' })
}

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <App />
  </Provider>,
  document.getElementById('root'),
)
