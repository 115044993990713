const COLOR = {
  GREEN_100: '#F6F9F7',
  GREEN_200: '#EDFFEA',
  GREEN_300: '#AEE8B9',
  GREEN_400: '#6EC580',
  GREEN_500: '#35BC50',
  GREEN_600: '#3EA553',
  GREEN_700: '#29813A',
  GREEN_800: '#214B29',
  GREEN_900: '#102615',

  BLUE_100: '#F1F2FA',
  BLUE_200: '#E2E8FD',
  BLUE_300: '#B0C1FF',
  BLUE_400: '#7A93FF',
  BLUE_500: '#4356FF',
  BLUE_600: '#061DED',
  BLUE_700: '#0000BA',
  BLUE_800: '#000087',
  BLUE_900: '#00003B',

  YELLOW_100: '#FFF7E4',
  YELLOW_200: '#FFCD5B',
  YELLOW_300: '#FFB200',
  YELLOW_400: '#D99700',
  YELLOW_500: '#724F00',

  RED_100: '#FFC8A9',
  RED_200: '#F99F6D',
  RED_300: '#F44217',
  RED_400: '#AC2100',
  RED_500: '#731600',

  NEUTRAL_100: '#FFFFFF',
  NEUTRAL_200: '#F9F9FC',
  NEUTRAL_300: '#EEEEF4',
  NEUTRAL_400: '#D0D2DA',
  NEUTRAL_500: '#8C8E97',
  NEUTRAL_600: '#616164',
  NEUTRAL_700: '#424244',
  NEUTRAL_800: '#242426',
  NEUTRAL_900: '#000000',
}

const TEXT_COLOR = {
  WHITE: COLOR.NEUTRAL_100,
  TERTIERY: COLOR.NEUTRAL_400,
  SECONDARY: COLOR.NEUTRAL_500,
  DEFAULT: COLOR.NEUTRAL_800,
  BLACK: COLOR.NEUTRAL_900,

  MIDDLE: COLOR.GREEN_400,
  EMPHASIS: COLOR.GREEN_500,
  SUCCESS: COLOR.BLUE_600,
  DANGER: COLOR.RED_300,

  PLACEHOLDER: COLOR.NEUTRAL_400,
  DISABLED: COLOR.NEUTRAL_400,
}

const STROKE_COLOR = {
  WHITE: COLOR.NEUTRAL_100,
  LIGHT: COLOR.NEUTRAL_300,
  DARK: COLOR.NEUTRAL_400,

  PRIMARY: COLOR.GREEN_500,
  SECONDARY: `#D2F2D1`,
  SUCCESS: COLOR.GREEN_400,
  ALERT: COLOR.RED_300,
}

const BUTTON_COLOR = {
  WHITE: COLOR.NEUTRAL_100,
  DISABLED: COLOR.NEUTRAL_300,
  HOVER: COLOR.NEUTRAL_300,

  ACTIVE: COLOR.GREEN_500,
  ACTIVE_HOVER: COLOR.GREEN_600,
  ALERT: COLOR.RED_300,
}

const ICON_COLOR = {
  WHITE: COLOR.NEUTRAL_100,
  TERTIERY: COLOR.NEUTRAL_400,
  SECONDARY: COLOR.NEUTRAL_500,
  DEFAULT: COLOR.NEUTRAL_800,

  PRIMARY: COLOR.GREEN_500,
  SUCCESS: COLOR.BLUE_600,
  DANGER: COLOR.RED_300,
  WARNING: COLOR.YELLOW_300,
}

const BG_COLOR = {
  WHITE: COLOR.NEUTRAL_100,
  DEFAULT: COLOR.NEUTRAL_200,
  DARK: COLOR.NEUTRAL_300,

  LIGHT_PRIMARY: COLOR.GREEN_100,
  PRIMARY: COLOR.GREEN_400,
  SECONDARY: `#D2F2D1`,

  PLACEHOLDER: COLOR.NEUTRAL_300,
}

export { COLOR, TEXT_COLOR, STROKE_COLOR, BUTTON_COLOR, ICON_COLOR, BG_COLOR }
