import { createAction, createReducer } from '@reduxjs/toolkit'

export interface MilestoneForm {
  milestoneId: number
  formState: boolean
}

const initialState: MilestoneForm = {
  // 생성이면 0, 수정이면 수정할 마일스톤 id
  milestoneId: 0,
  formState: false,
}

export const milestoneAction = createAction<MilestoneForm>('MILESTONE_ACTION')

const MilestoneReducer = createReducer(initialState, (builder) => {
  builder.addCase(milestoneAction, (state, action) => {
    state.milestoneId = action.payload.milestoneId
    state.formState = action.payload.formState
  })
})

export default MilestoneReducer
