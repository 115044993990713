import styled, { createGlobalStyle } from 'styled-components'
import GreenBackground from '../../assets/default/bg_green_circle.svg'
import icPopupCancel from '../../assets/default/ic_popup_cancel.svg'
import GmarketSansTTFBold from '../../assets/fonts/GmarketSansTTFBold.ttf'
import { BG_COLOR, STROKE_COLOR, TEXT_COLOR } from './styled/Colors'
import { TYPOGRAPHY } from './styled/Typography'

export const supportDeviceSize = 1080

// 반응형 미디어 사이즈 정의
const size = {
  mobile: '1080px',
  desktop: '1080px',
}
export const device = {
  mobile: `max-width: ${size.mobile}`,
  // desktop: `(min-width: ${size.desktop})`,
}

export const GlobalStyle = createGlobalStyle`
@font-face{
  font-family: 'GmarketSansTTFBold';
  src: local('GmarketSansTTFBold'), url(${GmarketSansTTFBold}) format('ttf');
  font-weight: bold;
  font-style: normal;
}

html {
font-size: 62.5%; // 1rem = 10px 로 변경 한 것, 바꾼 이유는 사파리에서 폰트가 너무 작은것은 허용하지 않기 때문.
// 참고링크 = https://stackoverflow.com/questions/68790660/setting-root-font-size-not-affecting-rem-units-in-safari-for-margin-padding-et
// 128px => 12.8rem , 4px => 0.4rem 가능!

  @media all and (max-width: ${supportDeviceSize}px) {
      // font-size: 31.25%;
      // font-size: 50%;
  }
}

body {
  background: white;
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans KR', -apple-system, sans-serif, Roboto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden; // 100vw의 경우 스크롤바까지 vw에 포함되어 추가
}

// MS EDGE에서 input에 자동으로 type=password 내부 영역 보여주는 눈 모양 아이콘 삭제
// 참고링크 = [https://docs.microsoft.com/en-us/microsoft-edge/web-platform/password-reveal]
input::-ms-reveal {
  display: none;
}

// Safari에서 input에 자동으로 type=password 에 뜨는 열쇠 아이콘 삭제
// 참고링크 = [https://newbedev.com/how-to-hide-autofill-safari-icon-in-input-field]
input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

// tab 이동 시 버튼 아웃라인 삭제
button:focus {
  outline: none;
  outline-offset: none;
}
`

// 모달에 닫기버튼(X)
export const CloseModalButton = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  background-image: url(${icPopupCancel});
  background-size: 2.49rem 2.49rem;
  width: 2.49rem;
  height: 2.49rem;
  cursor: pointer;
  position: absolute;
  top: 2.4rem;
  right: 2.6rem;

  @media screen and (${device.mobile}) {
    top: 1.81rem;
    right: 2.09rem;
    transform: scale(0.8);
  }
`

//

// 모달에 초록 동그라미
export const GreenCircleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${GreenBackground});
  background-size: 7.89rem 7.89rem;
  width: 7.89rem;
  height: 7.89rem;
  font-size: 4.8rem;

  @media screen and (${device.mobile}) {
    background-size: contain;
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
  }
`

//

// 왼쪽 사이드바로 인해 left값이 들어간 wrap입니다..!
export const ContentWrap = styled.div`
  /* @media screen and (max-width: 1920px) {
    left: 45.6rem; //  제플린 값
  }

  @media screen and (max-width: 1720px) {
    left: 38.4rem;
  } */

  /* @media screen and (min-width: 1920px) { 
    left: 440rem;
  } */
  margin: 0 auto;

  /* BiddingHeader 좌측 고정시킴 */
  /* left: 10rem; */

  width: 77.8rem;
  /* left: 456rem; */ //  제플린 값
  /* left: 392rem; // 맥북 크기 */
  position: relative;
`

export const ProjectWorkerContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 108rem;
  margin: 5.7rem auto 20rem auto;
  box-sizing: border-box;

  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  .main_titleBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 68rem;
    .title {
      ${TYPOGRAPHY.BODY1_MEDIUM};
      color: ${TEXT_COLOR.DEFAULT};
    }
    .pdfLink {
      ${TYPOGRAPHY.BODY2_MEDIUM};
      color: ${TEXT_COLOR.EMPHASIS};
      cursor: pointer;
    }
  }

  @media screen and (${device.mobile}) {
    width: 100%;
    padding: 1.7rem 2rem 13.18rem;
    margin-top: 1rem;
    margin-bottom: 0;

    .main_titleBox {
      width: 100%;

      .title {
        ${TYPOGRAPHY.BODY2_MEDIUM};
      }
      .pdfLink {
        ${TYPOGRAPHY.BODY2_MEDIUM};
      }
    }
  }
`

export const HoverAlert = styled.div`
  display: flex;
  /* background-color: #3b5cf6; */
  box-sizing: border-box;

  background-color: ${(props) => {
    if (props.blue) {
      return '#fff'
    } else if (props.green) {
      return BG_COLOR.LIGHT_PRIMARY
    } else if (props.closedRecruitment) {
      return BG_COLOR.DARK
    } else if (props.notGridge) {
      return '#f7f7f7'
    } else {
      return ''
    }
  }};
  width: ${(props) => {
    if (!props.detailPage) {
      return '103.07rem'
    } else {
      return 'max-content'
    }
  }};

  & .underline {
    ${TYPOGRAPHY.BODY2_REGULAR}
    color:${TEXT_COLOR.EMPHASIS};
    cursor: pointer;
  }

  height: max-content;
  padding: ${(props) => {
    if (props.blue) {
      return '2.46rem 3.58rem'
    } else if (props.detailPage) {
      return '1.2rem 6.4rem'
    } else if (props.green || props.closedRecruitment || props.notGridge) {
      return '1.25rem'
    } else {
      return ''
    }
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  border-radius: ${(props) => {
    if (props.blue) {
      return '2.0rem'
    } else if (props.green || props.closedRecruitment | props.notGridge) {
      return '4px'
    } else {
      return ''
    }
  }};

  box-shadow: ${(props) => {
    if (props.blue) {
      return ' 0 0.6rem 3.0rem 0 rgba(101, 101, 101, 0.22)'
    } else {
      return ''
    }
  }};

  border: ${(props) => {
    if (props.blue) {
      return 'solid 0.1rem #3b5cf6'
    } else if (props.green) {
      return `solid 0.1rem ${STROKE_COLOR.SUCCESS}`
    } else if (props.notGridge) {
      return 'solid 0.8px #e5e5e5'
    } else if (props.closedRecruitment) {
      return `solid 0.1rem ${STROKE_COLOR.DARK}`
    } else {
      return ''
    }
  }};

  right: ${(props) => {
    if (props.blue) {
      return '0rem'
    } else {
      return ''
    }
  }};

  margin-top: ${(props) => {
    if (props.blue) {
      return ' 4.3rem'
    } else if (props.green) {
      return '2rem'
    } else {
      return ''
    }
  }};
  right: ${(props) => {
    if (props.blue) {
      return '0rem'
    } else {
      return ''
    }
  }};

  & span {
    ${TYPOGRAPHY.BODY2_REGULAR};
    font-size: ${(props) => {
      if (props.blue) {
        return ' 3.1rem'
      } else if (props.green || props.notGridge || props.closedRecruitment) {
        return '1.6rem'
      } else {
        return ''
      }
    }};

    text-align: center;
    color: ${(props) => {
      if (props.blue) {
        return '#3b5cf6'
      } else if (props.green) {
        return '#000'
      } else {
        return ''
      }
    }};
  }

  @media screen and (${device.mobile}) {
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    padding: 0.75rem;
    margin-top: 0;
    margin-bottom: ${(props) => (props.detailPage ? '7rem' : 'unset')};

    .underline {
      font-size: 1.2rem;
      margin-left: 0;
    }

    span {
      font-size: 1.2rem;
      letter-spacing: -0.05rem;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
`
