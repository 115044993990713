const initialState = {
  NerdLoginModal: false,
  NerdChangePasswordModal: {
    display: false,
    navigation: '',
  },
  NerdWithdrawalModal: false,
  ApplyModal: false,
  ApplyNonLoginModal: false,
  ClosedApplyModal: false,
  FirstJobChoiceModal: false,
  FirstJobChoiceModalClicked: false,
  OfferGridgeTestModal: false,
  TakeGridgeTestModal: false,
  OtherPlatformApplyModal: false,
  ApplyNewSkillModal: false,
  AlreadyPassedSkillModal: false,
  SeeMoreDetailsModal: {
    display: false,
    text: '',
  },
  ProjectNotificationModal: {
    display: false,
    date: null,
  },
  RecruitmentModal: {
    display: false,
    mode: '',
    data: {
      id: 0,
      title: '',
      masterPlatformTypeId: 0,
      projectCoreTech: '',
      description: '',
      leftRecruit: 0,
    },
  },
  RecruitmentManageModal: { display: false, mode: '', func: null },
  MemberDropModal: { display: false, func: null },
  WorkerResultModal: { disaplay: false, mode: '' },
  OnlyPCSupportedModal: false,
  WorkingDescriptionModal: { display: false, outsourceId: 0, outsourceWorkerId: 0, refreshWorkHistory: null },
  OnlyPCSupportedProjectModal: false,
  NotModifyAndDeleteModal: { display: false, outsourceWorkerId: 0, createOutsourceWorkerHistory: null },
  MilestoneDeleteModal: { display: false, milestoneId: 0 },
  WorkscopeDeleteModal: { display: false, workscopeId: 0 },
  ValidationModal: { display: false, title: '', subtitle: '' },
  PlatformDeleteModal: { display: false, platformId: 0 },
  ToastModal: { display: false, mode: '' },
  ReportModal: { display: false, url: '' },
  SubTaskDeleteModal: { display: false, func: null },
  IssueRequestModal: { display: false, id: null, func: null },
  IssueTrackModal: { display: false, id: null, func: null },
  NewTaskModal: { display: false, func: null },
  MilestoneCloseModal: { display: false },
  TaskDeleteModal: { display: false, taskId: null },
}

const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'NERD_LOGIN_ON': {
      return { ...state, NerdLoginModal: true }
    }
    case 'NERD_LOGIN_OFF': {
      return { ...state, NerdLoginModal: false }
    }
    case 'NERD_CHANGE_PASSWORD_ON': {
      return {
        ...state,
        NerdChangePasswordModal: {
          display: true,
          navigation: action.data,
        },
      }
    }
    case 'NERD_CHANGE_PASSWORD_OFF': {
      return {
        ...state,
        NerdChangePasswordModal: {
          display: false,
          navigation: '',
        },
      }
    }
    case 'NERD_WITHDRAWAL_ON': {
      return { ...state, NerdWithdrawalModal: true }
    }
    case 'NERD_WITHDRAWAL_OFF': {
      return { ...state, NerdWithdrawalModal: false }
    }
    case 'APPLY_ON': {
      return { ...state, ApplyModal: true }
    }
    case 'APPLY_OFF': {
      return { ...state, ApplyModal: false }
    }
    case 'APPLY_NON_LOGIC_ON': {
      return { ...state, ApplyNonLoginModal: true }
    }
    case 'APPLY_NON_LOGIC_OFF': {
      return { ...state, ApplyNonLoginModal: false }
    }
    case 'CLOSED_APPLY_ON': {
      return { ...state, ClosedApplyModal: true }
    }
    case 'CLOSED_APPLY_OFF': {
      return { ...state, ClosedApplyModal: false }
    }
    case 'FIRST_JOB_CHOICE_MODAL_ON': {
      return { ...state, FirstJobChoiceModal: true }
    }
    case 'FIRST_JOB_CHOICE_MODAL_OFF': {
      return { ...state, FirstJobChoiceModal: false }
    }
    case 'FIRST_JOB_CHOICE_MODAL_CLICKED_TO_TRUE': {
      return { ...state, FirstJobChoiceModalClicked: true }
    }
    case 'FIRST_JOB_CHOICE_MODAL_CLICKED_TO_FALSE': {
      return { ...state, FirstJobChoiceModalClicked: false }
    }
    case 'OFFER_GRIDGE_TEST_MODAL_ON': {
      return { ...state, OfferGridgeTestModal: true }
    }
    case 'OFFER_GRIDGE_TEST_MODAL_OFF': {
      return { ...state, OfferGridgeTestModal: false }
    }
    case 'TAKE_GRIDGE_TEST_MODAL_ON': {
      return { ...state, TakeGridgeTestModal: true }
    }
    case 'TAKE_GRIDGE_TEST_MODAL_OFF': {
      return { ...state, TakeGridgeTestModal: false }
    }
    case 'OTHER_PLATFORM_APPLY_MODAL_ON': {
      return { ...state, OtherPlatformApplyModal: true }
    }
    case 'OTHER_PLATFORM_APPLY_MODAL_OFF': {
      return { ...state, OtherPlatformApplyModal: false }
    }
    case 'APPLY_NEW_SKILL_MODAL_ON': {
      return { ...state, ApplyNewSkillModal: true }
    }
    case 'APPLY_NEW_SKILL_MODAL_OFF': {
      return { ...state, ApplyNewSkillModal: false }
    }
    case 'ALREADY_PASSED_SKILL_MODAL_ON': {
      return { ...state, AlreadyPassedSkillModal: true }
    }
    case 'ALREADY_PASSED_SKILL_MODAL_OFF': {
      return { ...state, AlreadyPassedSkillModal: false }
    }
    case 'SEE_MORE_DETAILS_MODAL_ON': {
      return { ...state, SeeMoreDetailsModal: { display: true, text: action.data } }
    }
    case 'SEE_MORE_DETAILS_MODAL_OFF': {
      return { ...state, SeeMoreDetailsModal: { display: false, text: '' } }
    }
    case 'PROJECT_NOTIFICATION_MODAL_ON': {
      return { ...state, ProjectNotificationModal: { display: true, date: null } }
    }
    case 'PROJECT_NOTIFICATION_MODAL_OFF': {
      return {
        ...state,
        ProjectNotificationModal: {
          display: false,
          date: action.data,
        },
      }
    }
    case 'ONLY_PC_SUPPORTED_MODAL_ON': {
      return { ...state, OnlyPCSupportedModal: true }
    }
    case 'ONLY_PC_SUPPORTED_MODAL_OFF': {
      return { ...state, OnlyPCSupportedModal: false }
    }
    case 'WORKING_DESCRIPTION_MODAL_OFF': {
      return { ...state, WorkingDescriptionModal: { display: false, outsourceId: null, outsourceWorkerId: null } }
    }
    case 'WORKING_DESCRIPTION_MODAL_ON': {
      return {
        ...state,
        WorkingDescriptionModal: {
          display: true,
          outsourceId: action.outsourceId,
          outsourceWorkerId: action.outsourceWorkerId,
          refreshWorkHistory: action.refreshWorkHistory,
        },
      }
    }

    case 'NOT_MODIFY_AND_DELETE_MODAL_ON': {
      return {
        ...state,
        NotModifyAndDeleteModal: {
          display: true,
          outsourceWorkerId: action.outsourceWorkerId,
          createOutsourceWorkerHistory: action.createOutsourceWorkerHistory,
        },
      }
    }

    case 'NOT_MODIFY_AND_DELETE_MODAL_OFF': {
      return {
        ...state,
        NotModifyAndDeleteModal: {
          display: false,
          outsourceWorkerId: null,
          createOutsourceWorkerHistory: null,
        },
      }
    }
    case 'MILESTONE_CLOSE_MODAL_ON': {
      return {
        ...state,
        MilestoneCloseModal: {
          display: true,
        },
      }
    }
    case 'MILESTONE_CLOSE_MODAL_OFF': {
      return {
        ...state,
        MilestoneCloseModal: {
          display: false,
        },
      }
    }
    case 'MILESTONE_DELETE_MODAL_ON': {
      return {
        ...state,
        MilestoneDeleteModal: {
          display: true,
          milestoneId: action.milestoneId,
        },
      }
    }
    case 'MILESTONE_DELETE_MODAL_OFF': {
      return {
        ...state,
        MilestoneDeleteModal: {
          display: false,
          milestoneId: null,
        },
      }
    }
    case 'TASK_DELETE_MODAL_ON': {
      return {
        ...state,
        TaskDeleteModal: {
          display: true,
          taskId: action.taskId,
        },
      }
    }
    case 'TASK_DELETE_MODAL_OFF': {
      return {
        ...state,
        TaskDeleteModal: {
          display: false,
          taskId: null,
        },
      }
    }
    case 'WORKSCOPE_DELETE_MODAL_ON': {
      return {
        ...state,
        WorkscopeDeleteModal: {
          display: true,
          workscopeId: action.workscopeId,
        },
      }
    }
    case 'WORKSCOPE_DELETE_MODAL_OFF': {
      return {
        ...state,
        WorkscopeDeleteModal: {
          display: false,
          platformId: null,
        },
      }
    }
    case 'VALIDATION_MODAL_ON': {
      return {
        ...state,
        ValidationModal: {
          display: true,
          title: action.title,
          subtitle: action.subtitle,
        },
      }
    }
    case 'VALIDATION_MODAL_OFF': {
      return {
        ...state,
        ValidationModal: {
          display: false,
        },
      }
    }
    case 'PLATFORM_DELETE_MODAL_ON': {
      return {
        ...state,
        PlatformDeleteModal: {
          display: true,
          platformId: action.platformId,
        },
      }
    }
    case 'PLATFORM_DELETE_MODAL_OFF': {
      return {
        ...state,
        PlatformDeleteModal: {
          display: false,
          platformId: null,
        },
      }
    }
    case 'TOAST_MODAL_ON': {
      return {
        ...state,
        ToastModal: {
          display: true,
          mode: action.mode,
        },
      }
    }
    case 'TOAST_MODAL_OFF': {
      return {
        ...state,
        ToastModal: {
          display: false,
          mode: '',
        },
      }
    }
    case 'REPORT_MODAL_ON': {
      return {
        ...state,
        ReportModal: {
          display: true,
          url: action.url,
        },
      }
    }
    case 'REPORT_MODAL_OFF': {
      return {
        ...state,
        ReportModal: {
          display: false,
          url: null,
        },
      }
    }
    case 'SUBTASK_DELETE_MODAL_ON': {
      return {
        ...state,
        SubTaskDeleteModal: {
          display: true,
          func: action.func,
        },
      }
    }
    case 'SUBTASK_DELETE_MODAL_OFF': {
      return {
        ...state,
        SubTaskDeleteModal: {
          display: false,
          func: null,
        },
      }
    }
    case 'ISSUE_REQUEST_MODAL_ON': {
      return {
        ...state,
        IssueRequestModal: {
          display: true,
          id: action.id,
          func: action.func,
        },
      }
    }
    case 'ISSUE_REQUEST_MODAL_OFF': {
      return {
        ...state,
        IssueRequestModal: {
          display: false,
          id: null,
          func: null,
        },
      }
    }
    case 'ISSUE_TRACK_MODAL_ON': {
      return {
        ...state,
        IssueTrackModal: {
          display: true,
          id: action.id,
          func: action.func,
        },
      }
    }
    case 'ISSUE_TRACK_MODAL_OFF': {
      return {
        ...state,
        IssueTrackModal: {
          display: false,
          id: null,
          func: null,
        },
      }
    }
    case 'NEW_TASK_MODAL_ON': {
      return {
        ...state,
        NewTaskModal: {
          display: true,
          func: action.func,
        },
      }
    }
    case 'NEW_TASK_MODAL_OFF': {
      return {
        ...state,
        NewTaskModal: {
          display: false,
          func: null,
        },
      }
    }
    default:
      return state
  }
}

export default ModalReducer
